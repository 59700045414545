import React from "react"
import { Link } from "gatsby"
import Seo from "../components/global/Seo"
import Header from "../components/global/header"
import Footer from "../components/global/footer"

export default function Error404() {
  return (
    <>
      <Seo
        title="Сторінка 404"
        description="Сторінка не знайдена. код - 404"
        image={``}
        url="/404/"
      />
      <Header />
      <div className="container-my">
        <h1>Сторінка 404</h1>
        <p>
          Сторінка, яку ви намагалися відкрити не знайдено. Код помилки - 404.
        </p>

        <ol>
          <li>Зателефонуйте і отримайте безкоштовну консультацію.</li>
          <li>
            Перейдіть на{" "}
            <Link to="/" replace>
              головну сторінку
            </Link>{" "}
            і продовжіть роботу з сайтом.
          </li>
        </ol>
      </div>
      <Footer />
    </>
  )
}
